var projectSettings = {
	slider: {
		speed: 6500,
		animationSpeed: 1500,
		arrows: true,
		pagination: true,
		paginationArrows: true
	},
	sliderFullHeightScrollButton: false,
	inlineSVG: [
		'.header__open-off-canvas img',
		'.header__call img',
		'.off-canvas__social img',
		'.footer__social img'
	],
	mapStyle: [{featureType:"all",elementType:"labels.text.fill",stylers:[{saturation:36},{color:"#333333"},{lightness:40}]},{featureType:"all",elementType:"labels.text.stroke",stylers:[{visibility:"on"},{color:"#ffffff"},{lightness:16}]},{featureType:"all",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"administrative",elementType:"geometry.fill",stylers:[{color:"#fefefe"},{lightness:20}]},{featureType:"administrative",elementType:"geometry.stroke",stylers:[{color:"#fefefe"},{lightness:17},{weight:1.2}]},{featureType:"landscape",elementType:"geometry",stylers:[{color:"#fcfaf6"},{lightness:20}]},{featureType:"poi",elementType:"geometry",stylers:[{color:"#f5f5f5"},{lightness:21}]},{featureType:"poi.park",elementType:"geometry",stylers:[{color:"#dedede"},{lightness:21}]},{featureType:"road.highway",elementType:"geometry.fill",stylers:[{color:"#0a8cf9"},{lightness:17},{visibility:"simplified"},{weight:"2.34"}]},{featureType:"road.highway",elementType:"geometry.stroke",stylers:[{color:"#6db9ff"},{lightness:29},{weight:"1"}]},{featureType:"road.highway",elementType:"labels",stylers:[{visibility:"simplified"}]},{featureType:"road.highway",elementType:"labels.text",stylers:[{visibility:"simplified"},{color:"#ffffff"}]},{featureType:"road.arterial",elementType:"geometry",stylers:[{color:"#a3a19d"},{lightness:18}]},{featureType:"road.local",elementType:"geometry",stylers:[{color:"#ffffff"},{lightness:16}]},{featureType:"transit",elementType:"geometry",stylers:[{color:"#f2f2f2"},{lightness:19}]},{featureType:"water",elementType:"geometry",stylers:[{color:"#c6dfdf"},{lightness:17}]}]
}
