$(document).ready(function() {
	initSelectric();
	initBooking();
	customGridLanding();
	customNoSliderFix();
	customBoxOffice();
	initSliderScrollButton();
})


// Override
initHeader = function () {
	
	function throttle(fn, threshhold, scope) {
	  threshhold || (threshhold = 250);
	  var last,
		  deferTimer;
	  return function () {
		var context = scope || this;
	
		var now = +new Date,
			args = arguments;
		if (last && now < last + threshhold) {
		  // hold on to it
		  clearTimeout(deferTimer);
		  deferTimer = setTimeout(function () {
			last = now;
			fn.apply(context, args);
		  }, threshhold);
		} else {
		  last = now;
		  fn.apply(context, args);
		}
	  };
	}

	// Detect if the header is over and image slider, if yes then give it a transparent background
	setHeaderTransparency();
	$(window).on('scroll', setHeaderTransparency);

	function setHeaderTransparency() {
		if (window.pageYOffset < 150) {
			$('.header').removeClass('header--compact');
			
			if ( $('.slider').length > 0 ) {
				$('.header').addClass('header--transparent');
			}
		} else {
			$('.header').addClass('header--compact');
			
			if ( $('.slider').length > 0 ) {
				$('.header').removeClass('header--transparent');
			}
		}
	}
}

initFooterCarousel = function () {
	$('.footer__logo-carousel').slick({
		slidesToShow: 5,
		arrows: true,
	})
}

initSlider = function() {
	$('.slider__inner').reformSlider({
		speed: 6000,
		animationSpeed: 1000
	});
	
	$('.featured-carousel__carousel').slick({
		arrows: true,
		slidesToShow: 2,
		infinite: true,
		responsive: [
			{
			  breakpoint: 640,
			  settings: {
				slidesToShow: 1
			  }
			}
		]
	})

	$('.accommodation-landing__item').each(function() {
		$(this).find('.accommodation-landing__slider').slick({
			slidesToShow: 1,
			infinite: true,
			appendArrows: $(this).find('.accommodation-landing__details-arrows')
		})
	})
}

initOtherRooms = function() {
	$('.other-rooms__carousel').slick({
		arrows: true,
		slidesToShow: 1,
		infinite: true
	});
}

initDetail = function () {
	$('.detail__features-toggle').on('click', function (e) {
		e.preventDefault();
		
		// Toggle the second list of features
		$(this).prev('.detail__features-list--additional').slideToggle();
		
		// Toggle the read more/less button text
		if ( $(this).text() === $(this).data('more-text') ) {
			$(this).text($(this).data('less-text'));
		} else {
			$(this).text($(this).data('more-text'));
		}
	});
	
	// Make sure the second list is visible again when screen size increases to medium
	if ( $('.detail__features').length > 0 ) {
		$(window).on('resize', function () {
			if (Foundation.MediaQuery.atLeast('medium')) {
				$('.detail__features-list--additional').show();
			}
		});
		
		// Make the price larger
		// $('.detail__rate').html(wrapPrice($('.detail__rate').html(), '<big>', '</big>'));
	}
	
}

wrapPrice = function (findIn, wrapBefore, wrapAfter) {
	// finds price strings like £100, or $400.50 
	// return findIn.replace(/(((£|\$|€)([0-9]*)([.|,])([0-9]*)|(£|\$|€)([0-9]*)))/g, wrapBefore + '$1' + wrapAfter);
}

initAccordions = function() {
	$('.accordion__inner-wrap').accordiom({
		showFirstItem: false
	});

	$('.booking__tabs-content-room-why-book').accordiom({
		showFirstItem: false
	})
}

var initSelectric = function() {
	$('select').selectric();
}

initGallery = function () {
	$('.gallery__item a').on('click', function () {
		var $clickedThumbnail = $(this);
		// Dynamically load the gallery slider into a reveal popup	
		var modal = '<div id="gallery__modal" class="gallery__modal full reveal loading" data-reveal><div class="gallery__modal-wrap grid-x grid-container"><div class="gallery__modal-image cell medium-10 medium-offset-1"><button class="gallery__modal-close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><img src="{{src}}"><button class="gallery__modal-prev">Previous</button><button class="gallery__modal-next">Next</button></div><div class="gallery__modal-content cell medium-10 medium-offset-1 grid-x grid-margin-x"><div class="gallery__modal-caption cell small-12 medium-6">{{caption}}</div><div class="gallery__modal-cta cell small-12 medium-6">{{cta}}</div></div></div></div>';
		
		modal = modal.replace('{{src}}', $(this).attr('href'));
		modal = modal.replace('{{caption}}', $(this).data('title') || '');
		
		var cta = $(this).data('description') || '';
		if ( ( typeof $(this).data('book-link') !== 'undefined' ) && ( $(this).data('book-link') !== '' ) ) {
			cta += '<a href="' + $(this).data('book-link') + '" class="cta-link" target="_blank">' + ($(this).data('book-title') || 'Book Now') + '</a>';
		}
		modal = modal.replace('{{cta}}', cta);
		
		var $modal = modal;
		
		$('body').append($modal);
		
		$('#gallery__modal').foundation().on('closed.zf.reveal', function () {
			$('#gallery__modal').remove();
		});
		
		$('#gallery__modal').foundation('open');

		$('.gallery__modal-next').on('click', function () {
			$('#gallery__modal').foundation('close');
			$('#gallery__modal').remove();
			if ( $clickedThumbnail.parent().next(':visible').length > 0 ) { // is the next thumb visible?
				$clickedThumbnail.parent().next(':visible').find('a').trigger('click');
			} else if ( $clickedThumbnail.parent().nextUntil(':visible').length > 0 ) { // no, so find the next visible one
				$clickedThumbnail.parent().nextAll(':visible').first().find('a').trigger('click');
				$('#gallery__modal').foundation('close');
				$('#gallery__modal').remove();
			}
		});
		
		$('.gallery__modal-prev').on('click', function () {
			$('#gallery__modal').foundation('close');
			$('#gallery__modal').remove();
			if ( $clickedThumbnail.parent().prev(':visible').length > 0 ) { // is the previous thumb visible?
				$clickedThumbnail.parent().prev(':visible').find('a').trigger('click');
			} else if ( $clickedThumbnail.parent().prevUntil(':visible').length > 0 ) { // no, so find the next visible one that comes before this
				$clickedThumbnail.parent().prevAll(':visible').last().find('a').trigger('click');
				$('#gallery__modal').foundation('close');
				$('#gallery__modal').remove();
			}
		}); 
		
		return false;
	});
	
	refreshMasonry();
	$('.gallery').on('filter-changed', function () {
		setTimeout(refreshMasonry, 600);
	});
	
	Foundation.onImagesLoaded($('.gallery__item img'), refreshMasonry);
	
	function refreshMasonry() {
		$('.gallery__grid').masonry({
			columnWidth: '.gallery__item-sizer',
			itemSelector: '.gallery__item',
			percentPosition: true,
			horizontalOrder: true
		});
	};
	
	// Remove the transition class after loading to avoid jumpy thumbnails
	setTimeout(function () {
		$('.gallery__item').removeClass('transition--2000');
	}, 2000);
	
	
	
	// Remove empty filter buttons (if not handled by the plugin)
	$('.filter__buttons button').each(function () {
		if ( $(this).text() == '' ) {
			$(this).remove();
		}
	});
}

var initBooking = function() {

	var bookingContainer = $('.booking__tabs-content-room form');
	var monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
	var dayNames   = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ];
	var nowTemp = new Date();
	var tomorrow = new Date();
		tomorrow.setDate(nowTemp.getDate() + 1);

	var $checkInField = bookingContainer.find('.check-in');
	var $checkOutField = bookingContainer.find('.check-out');

	$checkInField.on('hide.datepicker', function () {
		var checkInDate  = $checkInField.datepicker('getDate');
		var checkOutDate = $checkOutField.datepicker('getDate');
		var dayAfterCheckIn = new Date(checkInDate);
			dayAfterCheckIn.setDate(checkInDate.getDate() + 1);

		// Ensure checkout comes after checkin.
		// Otherwise it all gets a bit Restaurant at the End of the Universe-ey.
		if ( checkInDate >= checkOutDate ) {
			$checkOutField.datepicker('setDate', dayAfterCheckIn);
		}

		$checkOutField.datepicker('setStartDate', dayAfterCheckIn);

		$checkOutField[0].focus();
	});

	var checkin = $checkInField.datepicker({
		format: 'dd',
		autoPick: true,
		autoHide: true,
		date: null,
		startDate: nowTemp,
		pick: function (e) {
			// User-facing date fields
			$('.check-in-month-year').text( monthNames[e.date.getMonth()] + ' ' + e.date.getFullYear() );
			$('.check-in-day').text( dayNames[e.date.getDay()] );

			// Formatted date for the booking engine
			$('input[name="arrive"]').val( e.date.getFullYear() + '-' + pad( e.date.getMonth() + 1*1 , 2) + '-' + e.date.getDate() );
		}
	});

	var checkout = $checkOutField.datepicker({
		format: 'dd',
		autoPick: true,
		autoHide: true,
		date: null,
		startDate: tomorrow,
		pick: function (e) {
			// User-facing date fields
			$('.check-out-month-year').text( monthNames[e.date.getMonth()] + ' ' + e.date.getFullYear() );
			$('.check-out-day').text( dayNames[e.date.getDay()] );

			// Formatted date for the booking engine
			$('input[name="depart"]').val( e.date.getFullYear() + '-' + pad( e.date.getMonth() + 1*1 , 2) + '-' + e.date.getDate() );
		}
	});
}

function pad(n, width, z) {
	z = z || '0';
	n = n + '';
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

var initAccommodation = function() {

	var i = 1;
	$('.accommodation-landing__item').each(function () {
		if ( i < 0 ) {
			$(this).addClass('accommodation-landing__item--odd');
		}

		i = i*-1;
	});
	
	
	if ( typeof getQueryParams(document.location.search) !== 'undefined' ) {
		var prefilterCat = getQueryParams(document.location.search).category;
		
		$('.filter__buttons button').each(function () {
			if ( $(this).text() === prefilterCat ) {
				$(this).trigger('click');
				
				$('html, body').animate({
					scrollTop: $('.accommodation-landing').offset().top // - $('.header').outerHeight()
				}, 1000);
			}
		});
	}
	
	
	$('.accommodation-landing__filter').accordiom({
		showFirstItem: false
	});
}



function getQueryParams(qs) {
	qs = qs.split('+').join(' ');

	var params = {},
		tokens,
		re = /[?&]?([^=]+)=([^&]*)/g;

	while (tokens = re.exec(qs)) {
		params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
	}

	return params;
}



function customGridLanding() {
	$('.landing-grid__item').each(function () {
		$(this).find('.landing-grid__content').append('<div class="landing-grid__arrows"></div>');
		
		$(this).find('.landing-grid__slider').slick({
			fade: true,
			arrows: true,
			appendArrows: $(this).find('.landing-grid__arrows')
		});
	});
}



function customNoSliderFix() {
	if ( $('.slider').length === 0 ) {
		$('body').addClass('no-slider');
	}
}



function customBoxOffice() {
	$('.rooms-offer__content-description img').appendTo('.rooms-offer__features'); // move images from the content into the sidebar
}

function initSliderScrollButton() {
	var sliderHeight = $('.slider').outerHeight();
	$('.slider__scroll-button .scroller').on('click', function() {
		$(window).scrollTop(sliderHeight - 20);
	});
}


initOffCanvas = function () {
	$('.off-canvas__nav-primary > ul > li').each(function () {
		// Automatically add the "Overview" links to the landing page
		if ( $(this).find('ul').length > 0 ) {
			var overviewLink = $(this).find('a').first().attr('href');
			$(this).find('ul').prepend('<li class="offcanvas__overview-link"><a href="' + overviewLink + '">Overview</a></li>');
		}
	});
	
	
	$('.off-canvas__nav-primary > ul > li > a').on('mouseover', function () {
		if ( $(this).next('.off-canvas__nav-sub').length > 0 ) { // only trigger if there are sub pages to show
			/*if ( $(this).parent().hasClass('open') ) {
				// second click of main item allows clicking directly through
				return true;
			} else {*/
				// close any other open items
				$('.off-canvas__nav-primary > ul > li.open').removeClass('open').find('.off-canvas__nav-sub').slideUp();
				
				// and open the new one
				$(this).parent().addClass('open');
				$(this).next().slideDown();
			//}
			//return false; // prevent navigating
		} else {
			$('.off-canvas__nav-sub').slideUp();
		}
	});
	
	
	$('.off-canvas__nav-primary').on('mouseleave', function () {
		$('.off-canvas__nav-primary > ul > li.open').removeClass('open').find('.off-canvas__nav-sub').slideUp();
	});
}